.input-text {
    border-radius: 8px;
    padding: 25px 10px 0px;
    position: relative;
    border: 1px solid #a9a1a1;
    background-color: rgb(245, 245, 245);
}

.focused {
    background-color: #ffffff;
}

.input-text input {
    width: 100%;
    font-size: 1rem;
    border: none;
    background-color: inherit;
}

.selected-border {
    border-color: #0099c5;
}

.error-border {
    border-color: #ff5252;
}

.success-border {
    border-color: #28fbab;
}

.neutral-border {
    border-color: #a9a1a1;
}