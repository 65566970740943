.navbar {
    width: 100%;
    position: fixed;
    top: 0px;
    padding: 20px 0;
    background-color: #ffffff;
    z-index: 1;
}

.navbar-flex {
    display: flex;
    justify-content: space-between;
}

.navbar-flex .item:first-child {
    width: 45%;
}

.navbar-group {
    display: flex;
    justify-content: space-between;
}

.navbar-flex .navbar-title {
    font-size:4.5vw;
    font-weight: 600;
}