.input-textarea {
    background-color: rgb(245, 245, 245);
    border-radius: 8px;
    padding: 25px 10px 0px;
    position: relative;
    border: 1px solid #a9a1a1;
}

.input-textarea textarea {
    padding: 0;
    min-height: 22px;
    outline: none;
    border: none;
    resize: none;
    width: 100%;
    height: 100%;
    overflow: auto;
    -moz-box-sizing: border-box;
    /* -webkit-box-sizing: border-box; */
    box-sizing: border-box;
    background-color: inherit;
}

.bottom-area {
    display: flex;
    justify-content: space-between;
}

.bottom-area .counter {
    font-size: 0.7rem;
}

.focused {
    background-color: #ffffff;
}

.selected-border {
    border-color: #0099c5;
}

.error-border {
    border-color: #ff5252;
}

.success-border {
    border-color: #28fbab;
}

.neutral-border {
    border-color: #a9a1a1;
}