.photo-selector {
    background-color: #e4f3ff;
    border: 1px solid #3c82a1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 42vw;
    height: 42vw;
    border-radius: 8px;
    align-items: center;
}

.add-photo-instructions {
    text-align: center;
}

.add-photo-instructions .title {
    font-size: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    color: #EA910B;
}

.add-photo-i {
    margin-right: 10px;
}

.add-photo-instructions .counter {
    font-size: 2.5vw;
    
}

.add-photo-icon {
    width: 50%;
    height: 50%;
}


.add-photo-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.add-photo-i {
    width: 6vw;
}