.create-post {
    position:relative;
}

.create-post .hide-list {
    position: fixed;
    width: 100%;
    top: 100%;
    transition: 0.5s;
    background-color: #ffffff;
}

.create-post .show-list {
    position: absolute;
    width: 100%;
    top: -1px;
    background-color: #ffffff;
    transition: 0.5s;
    height: 100%;
}

.cover-wrapper {
    display: flex;
    margin-top: 20%;
    justify-content: center;
}

.spaced {
    justify-content: space-between;
}

.flex-centered {
    justify-content: center;
}

.create-post .form {
    margin-top: 40px;
}

.input-group {
    display: flex;
    justify-content: space-between;
}