.compact-item {
    padding: 10px 0px;
    border-top: 1px solid #d4d4d4;
    display: flex;
    align-items: center;
}

.compact-item:hover {
    background-color: aliceblue;
}

.compact-item img {
    width: 45px;
}

.compact-item span:nth-child(2) {
    width: 67%;
    margin-left: 5%;
}

.compact-item span:nth-child(3) {
    font-size: 1.2rem;
    margin-top: 5px;
}