.dynamic-placeholder {
    position: absolute;
    top: 14px;
    left: 10px;
    transition: 0.1s;
    pointer-events: none;
}

.dynamic-placeholder .content {
    position: relative;
}

.retracted {
    font-size: 0.8rem;
    top: 5px;
    opacity: 0.5;
    color: rgb(0, 0, 0);
}

.placeholding {
    font-size: 1rem;
    top: 13px;
    opacity: 1;
    color: rgb(0, 0, 0);
}

.required-asterix {
    position: absolute;
    right: -11px;
    font-size: 0.4rem;
    color: #ff0000;
}