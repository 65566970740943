.container {
    margin: 0 3%;
}

.close-x {
    width: 7vw;
    height: 7vw;
    font-weight: 600;
}

.arrow-left {
    width: 2rem;
    height: 2rem;
    font-weight: 600;
    padding-top: 2px;
}

.check-mark {
    width: 1.6rem;
    height: 1.6rem;
    color: #4ecc00;
}

.touched {
    background-color: rgb(235, 246, 255);
}

.input-wrapper {
    margin-bottom: 5vw;
}

textarea:focus, input:focus{
    outline: none;
}
