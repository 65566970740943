.large-dropdown {
    display: flex;
    flex-flow: row;
    background-color: #f5f5f5;
    padding: 10px;
    border-width: 1px;
    border-radius: 5px;
    border: 1px solid #ffffff;
}

.large-dropdown .text {
    flex: 1;
    text-align: center;
}

.large-dropdown .icon {
    margin-top: 3px;
}