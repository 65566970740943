.selection-nav {
    width: 100%;
    position: fixed;
    top: 0px;
    padding: 20px 0;
    background-color: #ffffff;
    z-index: 1;
}

.selection-nav-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selection-nav .nav-item:last-child {
    flex: 1 1 auto;
    margin-left: 8%;
}